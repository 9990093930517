export const Icons = ({
  name,
  color = '#16325C',
  size = '20',
}) => {
  switch (name) {
    default:
      return <></>
  }
}

